import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { HistoryService } from "app/core/services/global/history/history.service";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { TableService } from "app/core/services/global/table/table.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { ElementService } from "app/core/services/pim/element.service";
import { FieldsetService } from "app/core/services/pim/fieldset.service";
import { Tab } from "app/shared/models/pim/element-resources.model";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { SectionTabComponent } from "./section-tab/section-tab.component";

@Component({
    selector: "app-element-update-tab",
    templateUrl: "./element-update-tab.component.html",
    encapsulation: ViewEncapsulation.None,
    styles: ['.p-copied {font-size:10px;}']
})
export class ElementUpdateTabComponent implements OnInit, OnDestroy {


    noSectionMessage: string;
    noSection: boolean;
    setValue: boolean;

    showDiff: boolean;
    history: any;

    tab: Tab;
    fieldTypeHistory: string = "";
    ArrayCopied = [];
    readonly routerLink = ["../../../../", MAESTRO_ROUTES.pim.elementType, MAESTRO_ROUTES.actions.update];
    readonly faClone = faClone;

    private _ngUnsubscribe: Subject<void>;
    currentId: number;

    tableHistory: boolean = false;
    checkboxHistory: boolean = false;
    colorsHistory: boolean = false;


    @ViewChild("historyModal", {static: true}) private swal: SwalComponent;
    @ViewChild("sectionTab") sectionTab: SectionTabComponent;


    constructor(
        private _translate: TranslateService,
        private _spinnerService: SpinnerService,
        private _toaster: ToastService,
        private _elementService: ElementService,
        private _historyService: HistoryService,
        private _sanitizer: DomSanitizer,
        private _fieldsetService: FieldsetService,
        private _tableService: TableService,
    ) {
        this.noSection = true;
        this.noSectionMessage = this._translate.instant("pim.element.noTab");
        this.showDiff = false;
        this.setValue = false;
        this._ngUnsubscribe = new Subject();
    }

    ngOnInit(): void {
        this.history = [];
        if (!window.location.href.includes("media")) {
            this._elementService.currentElement.pipe(takeUntil(this._ngUnsubscribe)).subscribe((element) => {
                const elementTypeId = element.elementTypeId.toString();
                 this.currentId = element.id;
                if (!this.routerLink.includes(elementTypeId)) {
                    this.routerLink.push(elementTypeId);
                }
            });
            this._elementService.currentTab.pipe(takeUntil(this._ngUnsubscribe)).subscribe((tab) => {
                this.tab = tab as any;
                this._prepareTab();
            });
            this._elementService.currentAuth.pipe(takeUntil(this._ngUnsubscribe)).subscribe((setValue) => {
                this.setValue = setValue;
            });
        }
    }

    ngOnDestroy(): void {
        this._ngUnsubscribe.next();
        this._ngUnsubscribe.complete();
    }

    /**
     * Save a field
     *
     * @param data
     */
    saveField(data: { field: any; payload: any; inputFieldset: any }): any {
        this._spinnerService.disable();
        data.field.isLoading = true;
        if (!data.field.countRequest) {
            data.field.countRequest = 1;
        } else {
            data.field.countRequest++;
        }

        this._elementService.updateField(this.currentId, data.payload).subscribe(
            (value) => {
                this._spinnerService.activate();
                data.field.countRequest--;
                if (data.field.countRequest === 0) {
                    data.field.isLoading = false;
                    this._toaster.show({
                        message: this._translate.instant("general.savedfield", {key: data.field.name ? data.field.name : data.field.input}),
                        type: "success",
                    });
                    if (data.field.elementValue) {
                        if(data.field.fieldType == 'listcascade'){
                            data.field.elementValue[0].value = JSON.parse(data.payload.value);
                        } else {
                            data.field.elementValue[0].value = data.payload.value;
                        }
                    } else {
                        if(data.field.fieldType == 'listcascade'){
                            data.field.value = JSON.parse(data.payload.value);
                        } else {
                            data.field.value = data.payload.value;
                        }
                    }
                    if (data.inputFieldset) {
                        data.field.collections[data.inputFieldset.indexCollection].elementValueView[data.inputFieldset.indexInput].value = value.data.elemVal;
                    }
                }
                this.sectionTab.initDisplayProductLink();
            },
            (_) => {
                this._spinnerService.activate();
                data.field.countRequest--;
                if (data.field.countRequest === 0) {
                    data.field.isLoading = false;
                    this._toaster.show({
                        message: this._translate.instant("general.nosavedfield", {key: data.field.name ? data.field.name : data.field.input}),
                        type: "danger",
                    });
                }
            }
        );
    }

    /**
     * Add a collection to a fieldset
     *
     * @param data
     */
    addCollection(data: { field: any; payload: any; duplicateFieldsetData: boolean }): void {
        this._spinnerService.disable();
        data.field.addCollection = true;

        let method, body;
        if (data.duplicateFieldsetData) {
            method = "duplicateFieldsetData";
            body = {source: data.payload.id, target: data.field.id};
        } else {
            method = "updateField";
            body = data.payload;
        }

        this._elementService[method](this.currentId, body)
            .pipe(map((r: any) => r.data))
            .subscribe(
                (newCollection) => {
                    this._spinnerService.activate();
                    data.field.addCollection = false;
                    this._toaster.show({
                        message: this._translate.instant("general.addedCollection", {key: data.field.name}),
                        type: "success",
                    });

                    if (Array.isArray(newCollection)) {
                        newCollection.forEach((collection) => {
                            data.field.collections.push(collection);
                        });
                    } else {
                        data.field.collections.push(newCollection);
                    }
                    this.sectionTab.initDisplayProductLink();
                },
                (_) => {
                    this._spinnerService.activate();
                    data.field.addCollection = false;
                    this._toaster.show({
                        message: this._translate.instant("general.notaddedCollection", {key: data.field.name}),
                        type: "danger",
                    });
                }
            );
    }

    /**
     * Remove a collection from a fieldset
     *
     * @param data
     */
    removeCollection(data: { field: any; collectionId: number }): void {
        this._spinnerService.disable();
        this._fieldsetService.removeCollection(data.collectionId).subscribe((_) => {
            this._toaster.show({
                message: this._translate.instant("general.removedCollection", {key: data.field.name}),
                type: "success",
            });
            data.field.collections = data.field.collections.filter((c) => c.id !== data.collectionId);
            this._spinnerService.activate();

            this.sectionTab.initDisplayProductLink();
        });
    }

    /**
     * Reorder collections positions
     *
     * @param collectionIds
     */
    switchCollections(collections: any): void {
        this._spinnerService.disable();
        this._elementService.setPositionColletion(collections.collectionIds, collections.field.id).subscribe((response: any) => {
            this._toaster.show({
                message: this._translate.instant("general.switchedCollection"),
                type: "success",
            });
            this._spinnerService.activate();
            collections.field.collections = response.data;
            this.sectionTab.initDisplayProductLink();
        });
    }

    /**
     * Open the history modal and fetch history information
     *
     * @param data
     */
    openHistory(data: any): void {
        if (data.hasOwnProperty("inputId") && data.hasOwnProperty("fieldType")) {
            const inputId = data.inputId;
            const fieldType = data.fieldType;
            this._historyService.getElementValueHistory(inputId).subscribe((history) => {
                this.history = history.map(item => ({...item, isCopy: false}))
                this.swal.fire();

            });
            this.fieldTypeHistory = fieldType;

            this.tableHistory = fieldType === "table" ? true : false;
            this.checkboxHistory = fieldType === "checkbox" ? true : false;
            this.colorsHistory = fieldType === "colors" ? true : false
        }
    }

    /**
     * Switch diff functionality
     */
    switchDiff(): void {
        this.showDiff = !this.showDiff;
    }

    /**
     * Copy a value to the clipboard
     * @param value
     * @param index
     */
    copyToClip(value: string, index: number): void {
        const currentElement = this.history[index];
        if (!currentElement.isCopy) {
            function listener(e) {
                e.clipboardData.setData("text/html", value);
                e.clipboardData.setData("text/plain", value);
                e.preventDefault();
            }

            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);
            currentElement.isCopy = true;
            setTimeout(() => currentElement.isCopy = false, 2000);
            // (<any>$("#tooltip-" + index)).tooltip("show");
            // setTimeout(() => (<any>$("#tooltip-" + index)).tooltip("hide"), 2000);
        }
    }

    /**
     * Sanitize a value
     * @param value
     * @returns
     */
    sanitize(value: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }

    /**
     * Prepare tabs to be displayed
     */
    private _prepareTab(): void {
        if (this.tab.fields && this.tab.fields.length) {
            if (!this.tab.fields[0].sections) {
                const fields = [
                    {
                        sections: {
                            [this._translate.instant("general.unnamedSection")]: [],
                        },
                    },
                ];

                for (let i = 0; i < this.tab.fields.length; i++) {
                    if (this.tab.fields[i].sections) {
                        Object.keys(this.tab.fields[i].sections).forEach((key) => {
                            fields[0].sections[key] = this.tab.fields[i].sections[key];
                        });
                        break;
                    }
                    fields[0].sections[this._translate.instant("general.unnamedSection")].push(this.tab.fields[i]);
                }

                this.tab.fields = fields;
            }
        }
    }
}
