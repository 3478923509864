import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { faEdit, faEye, faSave, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faAlignJustify, faDownload, faMinusCircle, faPlusCircle, faSort, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CodeModel } from "@ngstack/code-editor";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { ProjectExportService } from "app/core/services/project/export/export.service";
import { VariableProjectService } from "app/core/services/project/project/variable.service";
import { TemplateService } from "app/core/services/project/template/template.service";
import { DROPOWN_SETTINGS } from "app/shared/components/form/drop-down/settings";
import { IMaestroEntity } from "app/shared/models";
import { fieldTypes } from "app/shared/models/field-type.enum";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { environment } from "environments/environment";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import Swal from "sweetalert2";

@Component({
    selector: "app-template-create",
    templateUrl: "./template-create.component.html",
    styleUrls: ["./template-create.component.scss"],
    styles: [
        `
            .images-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                max-height: 400px;
                overflow-y: auto;
            }
            .image-item {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                margin: 10px;
            }
        `,
    ],
})
export class TemplateCreateComponent implements OnInit {
    form: FormGroup;
    exportTypes: IMaestroEntity[] = [];
    twigs = [];
    updateMode = false;
    initialCode: string = "";
    isSubmit: boolean = false;
    projectsNameSelectTemplate: string = "";
    dropdownSettingsSingle = DROPOWN_SETTINGS.single;

    actualJsx: string;
    actualIndt: string;
    actualPreviews: Array<any> = [];

    dedicatedCSSFiles: Array<any> = [];
    dedicatedJSFiles: Array<any> = [];

    private fileJsx: File = null;
    private fileIndt: File = null;
    private filesPreview: FileList = null;

    private filesCSS: FileList = null;
    private filesJS: FileList = null;

    existingExport = -1;
    hideVariables = true;
    isTwigChange: boolean = false;
    deleteUseVariableArray: any = "";
    indexDeleteVariableArray: Array<any> = [];
    indexDeleteInputArray: Array<any> = [];
    variableProjects: IMaestroEntity[];
    variableProjectsNoFilter: IMaestroEntity[];

    readonly faPlus = faPlusCircle;
    readonly faMinus = faMinusCircle;
    readonly faSave = faSave;
    readonly faTimes = faEye;
    readonly faTimesCircle = faTimesCircle;
    readonly faAlignJustify = faAlignJustify;
    readonly faTrash = faTrash;
    readonly faSort = faSort;
    readonly faEdit = faEdit;

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        itemsShowLimit: 6,
        allowSearchFilter: true,
        enableCheckAll: true,
        selectAllText: this._translateService.instant("general.select_all"),
        unSelectAllText: this._translateService.instant("general.unselect_all"),
        searchPlaceholderText: this._translateService.instant("general.search"),
        noDataAvailablePlaceholderText: this._translateService.instant("general.no.data"),
    };

    /**
     * Sortable config for sections
     */
    readonly sortableJSOptions = {
        ghostClass: "placeholder",
        handle: ".sortable",
        selectedClass: "selected", // The class applied to the selected items
        onEnd: function (event: any) {
            const from = event.oldIndex;
            const to = event.newIndex;

            this.array_move(this.form.controls.variables.controls, from, to);
            this.array_move(this.form.value.variables, from, to);
            this.form.markAsDirty();
        }.bind(this),
    };

    /**
     * Sortable config for sections
     */
    readonly sortableJSOptionsInput = {
        ghostClass: "placeholder",
        handle: ".sortableInput",
        selectedClass: "selected", // The class applied to the selected items
        onEnd: function (event: any) {
            const from = event.oldIndex;
            const to = event.newIndex;

            let variableIndex = Number(event.srcElement.id.split("listVariableInput")[1]);

            if (this.form.controls.variables.controls[variableIndex]) {
                this.array_move(this.form.controls.variables.controls[variableIndex].controls.inputs.controls, from, to);
                this.array_move(this.form.value.variables[variableIndex].inputs, from, to);
            }
            this.form.markAsDirty();
        }.bind(this),
    };
    readonly faDownload = faDownload;

    damUrl = environment.damUrl;

    pictoList = [];
    code: string = `<!DOCTYPE html>
<html>
    <head>
        {% block head %}
            <title>{% block title %}{% endblock %} - My Webpage</title>
        {% endblock %}
    </head>
    <body>
        <div id="content">{% block content %}{% endblock %}</div>
        <div id="footer">{% block footer %}{% endblock %}</div>
    </body>
</html>`;

    codeModel: CodeModel = {
        language: "twig",
        uri: "main.twig.html",
        value: this.code,
        dependencies: ["@types/node", "@ngstack/translate", "@ngstack/code-editor"],
    };

    options = {
        contextmenu: false,
        minimap: {
            enabled: false,
        },
        autoIndent: true,
        tabCompletion: false,
    };

    fieldTypes = [];

    currentList = [];

    readonly fontPathPlaceholder = "/Users/administrateur/Fonts/";

    templateId: number;

    @ViewChild("saveTwigModal") swal: SwalComponent;
    @ViewChild("updateListModal") swalList: SwalComponent;
    @ViewChild("warningTwigSaveModal") warningTwigSaveModal: SwalComponent;
    @ViewChild("warningVariableSaveModal") warningVariableSaveModal: SwalComponent;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private fb: FormBuilder,
        private _service: TemplateService,
        private _toastService: ToastService,
        private _translateService: TranslateService,
        private _layout: LayoutService,
        private _sanitizer: DomSanitizer,
        private _modalService: SwalModalService,
        private _projectExportService: ProjectExportService,
        private cdRef: ChangeDetectorRef,
        private _variableProjectService: VariableProjectService
    ) {}

    ngOnInit() {
        this.templateId = this._route.snapshot.params.id;

        this.form = this.fb.group({
            twigName: [""],
            exportType: [""],
            twigContent: [this.code],
            twigId: [""],
            twigNbTemplate: [0],
            variables: this.fb.array([], this.uniqueValues()),
            templateName: ["", Validators.required],
            fileJsx: [null],
            fileIndt: [null],
            previewsTemplate: [null],
            filesCSS: [null],
            filesJS: [null],
            regeneratedAt: ["", Validators.required],
            numberPage: [0, Validators.min(0)],
            numberElement: [0, Validators.min(0)],
            suffix: [""],
            fontPath: [""],
            pictos: [[]],
            options: this.fb.array([]),
            positionMedias: this.fb.array([]),
        });

        fieldTypes.forEach((fieldType) => {
            this.fieldTypes.push(fieldType);
        });
        this.fieldTypes.push("list multiple");
        this._route.data.subscribe((data) => {
            this._service.getPictos().subscribe((pictos) => {
                this.pictoList = pictos.map((p) => ({ id: p.id, name: p.name, thumb: p.thumb }));
                this._service.getTwigs().subscribe((response) => {
                    this.twigs = response.data;
                    if (this.templateId) {
                        this.getAllVariable(data.resources[2]);
                        this._prepareForm(data);
                    } else {
                        this.variableProjectsNoFilter = data.resources[1];
                        this.variableProjects = [];
                        let types = Object.keys(data.resources[1]);
                        types.forEach((type) => {
                            this.variableProjects[type] = [];
                            let fieldTypes = Object.keys(data.resources[1][type]);
                            fieldTypes.forEach((fieldType) => {
                                this.variableProjects[type][fieldType] = [];
                                this.variableProjects[type][fieldType] = this.filterTypeVariableProject(type, fieldType);
                            });
                        });
                        this.variableProjects = data.resources[1];
                        const excludedExportTypes = ["CSV", "SOCIAL", "JSON"];

                        if (data.resources[0] && data.resources[0].length > 0) {
                            data.resources[0].forEach((exportType) => {
                                if (exportType.value !== "CSV" && exportType.value !== "JSON" && (this.exportTypes.length == 0 || !this.exportTypes.map((e) => e.id).includes(exportType.id))) {
                                    this.exportTypes.push(exportType);
                                }
                            });
                            this.exportTypes = this.exportTypes.map((exportType) => <any>{ id: exportType.id, name: exportType.value });
                        }

                        this._layout.breadcrumb.setPath({ routerLink: null, name: this._translateService.instant("breadcrumb.project.templates.create") }, 2);
                    }
                });
            });
        });

        this._layout.breadcrumb.setPath(
            { routerLink: `/${MAESTRO_ROUTES.projects.base}/${MAESTRO_ROUTES.projects.templates}`, name: this._translateService.instant("breadcrumb.project.templates.list") },
            1
        );
    }

    getAllVariable(data){
        
        this.variableProjectsNoFilter = data;
        this.variableProjects = [];
        let types = Object.keys(data);

        types.forEach((type) => {
            this.variableProjects[type] = [];
            let fieldTypes = Object.keys(data[type]);

            if (type == "media") {
                this.variableProjectsNoFilter[type].forEach((variable) => {
                    this.variableProjects[type].push(variable);
                });
            } else {
                fieldTypes.forEach((fieldType) => {
                    this.variableProjects[type][fieldType] = this.filterTypeVariableProject(type, fieldType);
                });
            }
        });
    }

    /**
     * Create / Update the template
     * @returns
     */
    onSubmit(stay: boolean = false) {
        this.isSubmit = !stay;
        if (this.form.invalid) {
            return;
        }

        if (!this.form.value.twigId && !this.form.value.twigName && !this.form.value.twigContent && !this.form.value.exportType) {
            this._toastService.show({ type: "danger", message: this._translateService.instant("templates.config.template") });
            return;
        }

        const htmlFile = Array.from(this.filesCSS ? this.filesCSS : []).concat(Array.from(this.filesJS ? this.filesJS : []));

        const template = {
            twigName: this.form.value.twigName,
            exportType: this.form.value.exportType,
            twigContent: this.form.value.twigContent,
            twigId: this.form.value.twigId,

            name: this.form.value.templateName,
            fileJsx: this.fileJsx,
            fileIndt: this.fileIndt,
            filesPreview: this.filesPreview,
            htmlFile: htmlFile,
            regeneratedAt: this.form.value.regeneratedAt,
            numberPage: this.form.value.numberPage,
            numberElement: this.form.value.numberElement,
            suffix: this.form.value.suffix,
            fontPath: this.form.value.fontPath,

            pictos: JSON.stringify(this.form.value.pictos),

            options: JSON.parse(JSON.stringify(this.form.value.options)),
            medias: JSON.stringify(this.form.value.positionMedias),
        };

        const options = template.options;
        options.forEach((option) => {
            option.listValue = option.listValue.join(";");
        });

        template.options = JSON.stringify(options);

        if (this.updateMode) {
            this.updateTemplate(template, stay);
            return;
        }
        this._service.createTemplate(template).subscribe((response) => {
            this.form.controls.fileJsx.setValue(null);
            this.form.controls.fileIndt.setValue(null);
            this.form.controls.previewsTemplate.setValue(null);
            if (!stay) {
                this._router.navigate(["../"], { relativeTo: this._route });
            }
        });
    }

    updateTemplate(template, stay) {
        this._service.updateTemplate(this.templateId, template).subscribe((response) => {
            this.updateVariables(false, stay);
            this.actualJsx = response.data.template.dedicatedFiles.jsx;
            this.actualIndt = response.data.template.dedicatedFiles.indt;
            this.actualPreviews = response.data.template.dedicatedFiles.previews;
            this.form.controls.fileJsx.setValue(null);
            this.form.controls.fileIndt.setValue(null);
            this.form.controls.previewsTemplate.setValue(null);
            for (let index = 0; index < response.data.template.options.length; index++) {
                let varProj = response.data.template.options[index].variableProject;
                this.form.controls.options["controls"][index].controls["variableProject"].setValue([varProj]);
            }

            for (let index = 0; index < response.data.template.medias.length; index++) {
                let varProj = response.data.template.medias[index].variableProject;
                this.form.controls.positionMedias["controls"][index].controls["variableProject"].setValue([varProj]);
            }
        });
    }

    /**
     * Return to the template list
     */
    onBack() {
        this._router.navigate(["/projects", "templates"]);
    }

    /**
     * Check if jsx & indt file are required
     * @returns
     */
    isFileRequired(): boolean {
        if (this.form.value.exportType > 0 || this.existingExport === 2 || this.existingExport === 5) {
            let exportType = this.exportTypes.find((e) => e.id + "" === this.form.value.exportType || e.id === this.existingExport);
            if (exportType) {
                return ["CDF", "Print", "Marketing", "BOOKLET"].includes(exportType.name);
            } else {
                return false;
            }
        }
    }

    /**
     * Check if jsx & indt file are required
     * @returns
     */
    isNbPageAndProductRequired(): boolean {
        if (this.form.value.exportType > 0 || this.existingExport === 2 || this.existingExport === 5) {
            let exportType = this.exportTypes.find((e) => e.id + "" === this.form.value.exportType || e.id === this.existingExport);
            if (exportType && !["BOOKLET"].includes(exportType.name)) {
                return true;
            } else {
                this.form.controls["numberPage"].setValue(0);
                this.form.controls["numberElement"].setValue(0);
                return false;
            }
        }
    }

    /**
     * Check if CSS & JS files are required
     * @returns
     */
    isCSSJSFileRequired(): boolean {
        if (this.existingExport === 5 || this.form.value.exportType == 5) {
            return true;
        }
        return false;
    }

    /**
     * Check if the user need to enter page number
     * @returns
     */
    isNumberPageRequired(): boolean {
        if (this.form.value.exportType > 0 || this.existingExport === 2 || this.existingExport === 5) {
            const exportName = this.exportTypes.find((e) => e.id + "" == this.form.value.exportType || e.id == this.existingExport).name;
            if ("Marketing" === exportName) {
                this.form.controls["numberPage"].setValue(1);
                this.form.controls["numberPage"].disable();
            } else {
                this.form.controls["numberPage"].enable();
            }

            return ["CDF", "Print", "Marketing"].includes(exportName);
        }
    }

    /**
     * Check if the user need to enter font's path
     * @returns
     */
    isFontPathRequired(): boolean {
        if (this.existingExport === 2 || this.existingExport === 6 || this.form.value.exportType == 2 || this.form.value.exportType == 6) {
            return true;
        }
    }

    /**
     * Add an option
     */
    addOption(): void {
        const control = <FormArray>this.form.controls.options;

        control.push(
            this.fb.group({
                id: [-1],
                name: ["", Validators.required],
                twigVar: [""],
                type: ["", Validators.required],
                listValue: [[]],
                variableProject: [],
            })
        );
    }

    /**
     * Add a position media
     */
    addPositionMedia(): void {
        const control = <FormArray>this.form.controls.positionMedias;

        control.push(
            this.fb.group({
                forElement: [false],
                id: [-1],
                name: ["", Validators.required],
                marker: ["", Validators.required],
                width: [0, Validators.required],
                height: [0, Validators.required],
                optional: [false],
                adjustmentOption: ["", Validators.required],
                variableProject: [null],
            })
        );
    }

    /**
     * Add value to a select option
     *
     * @param index
     * @returns
     */
    addValueToOption(index: number): void {
        if ($("#newValue" + index).val() === "") {
            return;
        }
        this.form.value.options[index].listValue.push($("#newValue" + index).val());

        $("#newValue" + index).val("");
    }

    /**
     * Get content for a value list in options
     *
     * @param index
     * @returns
     */
    getContent(index: number): string {
        if (!this.form.value.options[index].listValue || this.form.value.options[index].listValue.length === 0) {
            return "";
        }
        return this.form.value.options[index].listValue.join(", ");
    }

    /**
     * Get content for a value list in options
     *
     * @param index
     * @returns
     */
    getContentVariable(index: number): string {
        if (!this.form.value.variables[index].listValue || this.form.value.variables[index].listValue.length === 0) {
            return "";
        }
        return this.form.value.variables[index].listValue.join(", ");
    }

    /**
     * Get content for a value list in options
     *
     * @param index
     * @returns
     */
    getContentInput(index: number, inputIndex: number): string {
        if (!this.form.value.variables[index].inputs[inputIndex].listValue || this.form.value.variables[index].inputs[inputIndex].listValue.length === 0) {
            return "";
        }
        return this.form.value.variables[index].inputs[inputIndex].listValue.join(", ");
    }

    /**
     * Set the jsx file on input
     *
     * @param files
     */
    handleFileJsxInput(files: FileList) {
        this.fileJsx = files.item(0);
    }

    /**
     * Set the indt file on input
     *
     * @param files
     */
    handleFileIndtInput(files: FileList) {
        this.fileIndt = files.item(0);
    }

    /**
     * Set the preview files on input
     *
     * @param files
     */
    handleFilesPreviewInput(files: FileList) {
        this.filesPreview = files;
    }

    /**
     * Set the css files on input
     *
     * @param files
     */
    handlefilesCSSInput(files: FileList) {
        this.filesCSS = files;
    }

    /**
     * Set the js files on input
     *
     * @param files
     */
    handlefilesJSInput(files: FileList) {
        this.filesJS = files;
    }

    trackByFn(index: any, item: any) {
        return item ? index : undefined;
    }

    /**
     * Remove an option from the form array
     *
     * @param index
     */
    removeOption(index: number): void {
        let options = [];
        this.form.value.options.splice(index, 1);
        (this.form.controls.options as FormArray).controls.splice(index, 1);
        options = this.form.value.options;
        (this.form.controls.options as FormArray).clear();
        this.cdRef.detectChanges();
        options.forEach((option) => {
            (this.form.controls.options as FormArray).push(
                this.fb.group({
                    id: [Number(option.id)],
                    twigVar: [option.twigVar],
                    name: [option.name, Validators.required],
                    type: [option.type, Validators.required],
                    listValue: [option.listValue || []],
                    variableProject: [option.variableProject || []],
                })
            );
        });
        this.cdRef.detectChanges();
    }

    /**
     * Remove a position from the form array
     *
     * @param index
     */
    removeMedia(index: number): void {
        let medias = [];
        this.form.value.positionMedias.splice(index, 1);
        (this.form.controls.positionMedias as FormArray).controls.splice(index, 1);
        medias = this.form.value.positionMedias;
        (this.form.controls.positionMedias as FormArray).clear();
        this.cdRef.detectChanges();
        medias.forEach((media) => {
            (this.form.controls.positionMedias as FormArray).push(
                this.fb.group({
                    forElement: [media.forElement],
                    id: [Number(media.id)],
                    name: [media.name, Validators.required],
                    marker: [media.marker, Validators.required],
                    width: [media.width, Validators.required],
                    height: [media.height, Validators.required],
                    optional: [media.optional],
                    adjustmentOption: [media.adjustmentOption, Validators.required],
                    variableProject: [media.variableProject ? [{ id: media.variableProject.id, name: media.variableProject.name }] : []],
                })
            );
        });
        this.cdRef.detectChanges();
    }

    /**
     * Update twig values
     */
    updateTwigValues(twig: any): void {
        if (this.form.value.variables.length > 0) {
            this.isTwigChange = true;
        }
        if (twig) {
            this.existingExport = this.twigs.find((t) => t.id + "" === twig + "").exportType;
            $(".newTwig").each(function () {
                $(this).hide();
            });

            this.getTwig(twig);
        } else {
            $(".newTwig").each(function () {
                $(this).show();
            });
            this.existingExport = -1;
        }
    }

    /**
     * Return the thumb for a picto
     *
     * @param pictoId
     * @returns
     */
    getPictoThumb(pictoId: number): SafeResourceUrl {
        let media = this.pictoList.find((p) => p.id === pictoId);
        if (media && media.thumbPath) {
            return this.damUrl + media.thumbPath;
        } else if (media && media.thumb) {
            return this._sanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + media.thumb);
        } else {
            return this.damUrl + "/default/default.png";
        }
    }

    /**
     * Empty form pictos
     */
    emptyPictoList(): void {
        this.form.patchValue({ pictos: [] });
    }

    /**
     * Listener for twig editor
     * @param value
     */
    onCodeChanged(value: string): void {
        this.form.patchValue({
            twigContent: value,
        });
    }

    onFieldTypeChanged(field: any): void {
        field.patchValue([]);
    }

    /**
     * Update the twig
     */
    updateTwig(stay: boolean): void {
        this.isSubmit = !stay;
        if (this.form.value.twigNbTemplate > 1) {
            this.swal.fire();
            Swal.close();
        } else {
            this.updateTwigTemplate("update");
        }
    }

    warningSave(stay: boolean = false): void {
        if (this.initialCode != this.form.value.twigContent) {
            this._service.projectNameByTemplate(this.templateId).subscribe((result) => {
                if (result.data !== "") {
                    this.projectsNameSelectTemplate = result.data;
                    this.updateTwig(stay);
                } else {
                    this.updateTwig(stay);
                }
            });
        } else {
            this.submitTwig();
        }
    }

    updateVariables(saveOnlyVar: boolean = false, stay: boolean): void {
        const variables = this.form.value.variables;
        if (this.deleteUseVariableArray.length != "") {
            this.warningVariableSaveModal.fire().then((result) => {
                if (result.isConfirmed) {
                    this.updateVariable(variables, this.templateId, saveOnlyVar, stay);
                } else {
                    this.addDeletedVariable();
                }
            });
        } else {
            this.updateVariable(variables, this.templateId, saveOnlyVar, stay);
        }
    }

    addDeletedVariable() {
        this.deleteUseVariableArray = "";
        this.indexDeleteVariableArray.reverse().forEach((variable) => {
            (this.form.controls.variables as FormArray).insert(
                variable.indexVariable,
                this.fb.group({
                    id: variable.variable.id,
                    name: [variable.variable.name, Validators.required],
                    type: [variable.variable.type, Validators.required],
                    inputs: this.fb.array([]),
                    listValue: [variable.variable.listValue],
                    forElement: [variable.variable.forElement],
                    isUse: [variable.variable.isUse],
                })
            );

            this.indexDeleteInputArray.forEach((input) => {
                if (variable.indexVariable <= input.indexVariable) {
                    input.indexVariable++;
                }
            });
        });
        this.cdRef.detectChanges();
        let inputs = [];
        this.indexDeleteInputArray.reverse().forEach((input) => {
            (this.form.controls.variables["controls"][input.indexVariable].controls.inputs as FormArray).push(
                this.fb.group({
                    id: input.variable.id,
                    name: [input.variable.name, Validators.required],
                    type: [input.variable.type, Validators.required],
                    listValue: [input.variable.listValue],
                })
            );

            inputs = this.form.controls.variables["controls"][input.indexVariable].controls.inputs.value;

            this.form.controls.variables["controls"][input.indexVariable].controls.inputs.clear();
            this.cdRef.detectChanges();
            this.getInputs(inputs, this.form.controls.variables["controls"][input.indexVariable].controls.inputs);
        });
        this.indexDeleteVariableArray = [];
        this.indexDeleteInputArray = [];
    }

    updateVariable(variables: Array<any>, templateId: number, saveOnlyVar: boolean, stay: boolean) {
        this._service.updateVariables(variables, this.form.value.twigId, templateId).subscribe((data) => {
            if (saveOnlyVar) {
                this._variableProjectService.getAllSort().subscribe((data) => {
                    this.getAllVariable(data.data);
                });
                this.getVariables(this.form.value.twigId);
                this.initialCode = this.form.value.twigContent;
            } else {
                this.warningSave(stay);
            }
        });
    }

    updateTwigTemplate(action: string) {
        let twigName = this.form.value.twigName;
        let twigId = null;
        if (action == "create") {
            const today = new Date();
            const date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const date_time = date + " " + time;

            twigName = this.form.value.twigName + "_duplicate_" + date_time;
        } else {
            twigId = this.form.value.twigId;
        }

        const twigContent = this.form.value.twigContent;
        const exportType = this.form.value.exportType;

        if (this.isTwigChange && this.projectsNameSelectTemplate != "") {
            this.warningTwigSaveModal.fire().then((result) => {
                if (result.isConfirmed) {
                    this._service.saveTwig(twigName, twigContent, exportType, this.templateId, action, twigId).subscribe((data) => {
                        this.isTwigChange = false;
                        this.submitTwig();
                    });
                }
            });
        } else {
            this._service.saveTwig(twigName, twigContent, exportType, this.templateId, action, twigId).subscribe((data) => {
                this.isTwigChange = false;
                this.submitTwig();
            });
        }

        Swal.close();
    }

    submitTwig() {
        this._toastService.show({ message: this._translateService.instant("general.saved"), type: "success" });

        if (this.isSubmit) {
            this._router.navigate(["../../"], { relativeTo: this._route });
        } else {
            this.getVariables(this.form.value.twigId);
            this.initialCode = this.form.value.twigContent;
        }
    }

    /**
     * Prepare the form with values (in update mode)
     *
     * @param data
     */
    private _prepareForm(data: any): void {
        if (data.resources[1] && data.resources[1].length > 0) {
            data.resources[1].forEach((exportType) => {
                if (exportType.value !== "CSV" && exportType.value !== "JSON" && (this.exportTypes.length == 0 || !this.exportTypes.map((e) => e.id).includes(exportType.id))) {
                    this.exportTypes.push(exportType);
                }
            });
        }
        this.exportTypes = this.exportTypes.map((exportType) => <any>{ id: exportType.id, name: exportType.value });
        const template = data.resources[0].template;
        const twig = data.resources[0].twig;
        this.actualJsx = template.dedicatedFiles.jsx;
        this.actualIndt = template.dedicatedFiles.indt;
        this.actualPreviews = template.dedicatedFiles.previews;

        this.dedicatedCSSFiles = template.dedicatedFiles.css;
        this.dedicatedJSFiles = template.dedicatedFiles.js;

        // Configure the editor
        this.code = twig.content || "";
        this.codeModel.value = twig.content;
        this.codeModel = { ...this.codeModel };

        this.form.patchValue({
            twigId: twig.id,
            twigName: twig.name,
            twigContent: twig.content,
            exportType: twig.exportType,
            twigNbTemplate: twig.templates.length,
            templateName: template.name,
            pictos: template.commonFiles["ai"]
                ? template.commonFiles["ai"].map((p) => ({
                      id: Number(p.idDam),
                      name: p.originalName,
                      thumb: this.pictoList.find((pl) => pl.id === Number(p.idDam)).thumb,
                  }))
                : [],

            numberPage: template.numberPage,
            regeneratedAt: template.regeneratedAt,
            numberElement: template.numberElement,
            suffix: template.suffix ? template.suffix : "",
            fontPath: template.fontPath ? template.fontPath : "",
        });
        this.initialCode = twig.content;

        this.updateTwigValues(twig.id);

        // Set options
        template.options.forEach((opt) => {
            (this.form.controls.options as FormArray).push(
                this.fb.group({
                    id: [Number(opt.id)],
                    twigVar: [opt.twigVar],
                    name: [opt.name, Validators.required],
                    type: [opt.type, Validators.required],
                    listValue: [opt.listValue || []],
                    variableProject: [opt.variableProject ? [{ id: opt.variableProject.id, name: opt.variableProject.name }] : []],
                })
            );
        });

        // Set media
        template.medias.forEach((media) => {
            (this.form.controls.positionMedias as FormArray).push(
                this.fb.group({
                    forElement: [media.forElement],
                    id: [media.id],
                    name: [media.name, Validators.required],
                    marker: [media.cleTwig, Validators.required],
                    width: [media.widthMedia, Validators.required],
                    height: [media.heightMedia, Validators.required],
                    optional: [media.optional],
                    adjustmentOption: [media.ajustement, Validators.required],
                    variableProject: [media.variableProject ? [{ id: media.variableProject.id, name: media.variableProject.name }] : []],
                })
            );
        });

        this.updateMode = true;
        this._layout.breadcrumb.setPath({ routerLink: null, name: this._translateService.instant("breadcrumb.project.templates.update") }, 2);
    }

    isTwigValid() {
        let result = false;
        let twig = this.codeModel.value;
        if (twig.indexOf("<?xml") === 0 || twig.indexOf("<!DOCTYPE html>") === 0 || twig.indexOf("{") === 0) {
            result = true;
        }

        return result;
    }

    getVariables(twigId: number) {
        this._service.getVariables(twigId).subscribe((data) => {
            (this.form.controls.variables as FormArray).clear();
            data.data.forEach((variable) => {
                let formGroupInput = [];
                if (variable.input.length > 0) {
                    formGroupInput = this.getInputs(variable.input, formGroupInput);
                }
                let type = variable.type ? variable.type : "textarea";
                if (formGroupInput.length > 0 && variable.type != "productLink") {
                    type = "fieldset";
                }
                (this.form.controls.variables as FormArray).push(
                    this.fb.group({
                        id: [Number(variable.id)],
                        name: [variable.name, Validators.required],
                        type: [type],
                        inputs: this.fb.array(formGroupInput, this.uniqueValues()),
                        listValue: [variable.listValue],
                        forElement: [variable.forElement],
                        isUse: [variable.isUse],
                        variableProject: [variable.variableProject ? [{ id: variable.variableProject.id, name: variable.variableProject.name }] : []],
                    })
                );
            });
        });
    }

    createVariable() {
        (this.form.controls.variables as FormArray).push(
            this.fb.group({
                id: -1,
                name: ["", Validators.required],
                type: ["textarea", Validators.required],
                inputs: this.fb.array([]),
                listValue: [[]],
                forElement: [false],
                isUse: [false],
                variableProject: [null],
            })
        );
    }

    createInput(index) {
        (this.form.controls.variables["controls"][index].controls.inputs as FormArray).push(
            this.fb.group({
                id: -1,
                name: ["", Validators.required],
                type: ["textarea", Validators.required],
                listValue: [[]],
                variableProject: [null],
            })
        );
    }

    getInputs(inputs, formGroupInput): any {
        inputs.forEach((input) => {
            formGroupInput.push(
                this.fb.group({
                    id: input.id,
                    name: [input.name, Validators.required],
                    type: [input.type ? input.type : "textarea", Validators.required],
                    listValue: [input.listValue],
                    variableProject: [input.variableProject ? [{ id: input.variableProject.id, name: input.variableProject.name }] : []],
                })
            );
        });
        return formGroupInput;
    }

    deleteVariable(name: any, isUse: boolean, indexVariable: number, indexInput?: number) {
        let inputs = [];
        this._modalService.deleteWithContent("modal.deleteVariable").then((result) => {
            if (result.isConfirmed) {
                if (isUse) {
                    if (this.deleteUseVariableArray == "") {
                        this.deleteUseVariableArray = name;
                    } else {
                        this.deleteUseVariableArray += ", " + name;
                    }
                }
                if (Number.isInteger(indexInput) && indexInput >= 0) {
                    this.indexDeleteInputArray.push({
                        indexVariable: indexVariable,
                        indexInput: indexInput,
                        variable: this.form.value.variables[indexVariable].inputs[indexInput],
                    });

                    this.form.controls.variables["controls"][indexVariable].controls.inputs["controls"].splice(indexInput, 1);
                    this.form.value.variables[indexVariable].inputs.splice(indexInput, 1);

                    inputs = this.form.value.variables[indexVariable].inputs;

                    this.form.controls.variables["controls"][indexVariable].controls.inputs.clear();
                    this.cdRef.detectChanges();

                    this.getInputs(inputs, this.form.controls.variables["controls"][indexVariable].controls.inputs);
                    this.cdRef.detectChanges();
                } else {
                    this.indexDeleteVariableArray.push({
                        indexVariable: indexVariable,
                        variable: this.form.value.variables[indexVariable],
                    });
                    this.form.controls.variables["controls"].splice(indexVariable, 1);
                    this.form.value.variables.splice(indexVariable, 1);
                }

                this.cdRef.detectChanges();
            }
        });
    }

    /**
     * Add value to a select option
     *
     * @param index
     * @returns
     */
    addValueToListVariable(index: number): void {
        if ($("#newValueVariable" + index).val() === "") {
            return;
        }
        this.form.value.variables[index].listValue.push($("#newValueVariable" + index).val());

        $("#newValueVariable" + index).val("");
    }

    /**
     * Add value to a select option
     *
     * @param index
     * @returns
     */
    addValueToListInput(index: number, indexInput: number): void {
        if ($("#listVariableInput" + index + " #newValueVariableInput" + indexInput).val() === "") {
            return;
        }

        this.form.value.variables[index].inputs[indexInput].listValue.push($("#listVariableInput" + index + " #newValueVariableInput" + indexInput).val());

        $("#listVariableInput" + index + " #newValueVariableInput" + indexInput).val("");
    }

    /**
     * Update the twig
     */
    updateListTwig(index: number, indexInput: number = -1): void {
        this.currentList = indexInput > 0 ? this.form.value.variables[index].inputs[indexInput].listValue : this.form.value.variables[index].listValue;
        
        let listValues = [];
        this.swalList.fire().then((result) => {
            if (result.value) {
                $(".inputList").each(function () {
                    listValues.push($(this).val());
                });
                
                
                if (indexInput >= 0) {
                    this.form.value.variables[index].inputs[indexInput].listValue = listValues;
                } else {
                    this.form.value.variables[index].listValue = listValues;
                }
            }
        });
    }

    addValueToList() {
        this.currentList.push("");
    }

    deleteValueList(indexValue: number) {
        this.currentList.splice(indexValue, 1);
    }

    variablesInvalid() {
        return this.form.controls.variables.invalid;
    }

    allFieldTypes(removeType) {
        return this.fieldTypes.filter((fieldType) => !removeType.includes(fieldType));
    }

    downloadDedicatedFile(extension, nameFile) {
        this._service.downloadDedicatedFile(this.templateId, extension, nameFile);
        return false;
    }

    /**
     * Control that the procotol & name is unique
     * @returns
     */
    uniqueValues(): ValidatorFn {
        return (formArray: FormArray): null => {
            const names = formArray.value.map((v) => v.name.toLowerCase());

            formArray.controls.forEach((formGroup: FormGroup) => {
                const control = formGroup.controls.name;

                let a = names.filter((v: string) => v.trim() === control.value.trim().toLowerCase()).length > 1;

                if (a && control.value) {
                    control.setErrors({ uniqueValue: true });
                } else {
                    const errors = control.errors;
                    if (errors && errors.uniqueValue) {
                        delete errors.uniqueValue;
                    }
                    if (errors && Object.keys(errors).length === 0) {
                        control.setErrors(null);
                    } else {
                        control.setErrors(errors);
                    }
                }
            });
            return null;
        };
    }

    array_move(arr: any[], oldIndex: number, newIndex: number): void {
        while (oldIndex < 0) {
            oldIndex += arr.length;
        }
        while (newIndex < 0) {
            newIndex += arr.length;
        }
        if (newIndex >= arr.length) {
            var k = newIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    }

    getTwig(idTwig: number) {
        this._service.getTwig(idTwig).subscribe((data) => {
            let twig = data.data;

            this.form.patchValue({
                twigId: twig.id,
                twigName: twig.name,
                twigContent: twig.content,
                exportType: twig.exportType,
                twigNbTemplate: twig.templates.length,
            });

            this.codeModel.value = twig.content;
            this.codeModel = { ...this.codeModel };

            this.getVariables(idTwig);
        });
    }

    validateContent(content: string, type: number) {
        this._service.validateContent(content, type /*, this.templateId*/).subscribe((data) => {
            this._modalService.info(this._translateService.instant("response.success.twig.validated"));
        });
    }

    showPreviews() {
        const templateId = this._route.snapshot.params.id;
        this._service.getPreviews(templateId, "Image").subscribe((response) => {
            const images = response.data;
            let swalContent =
                '<div style="display: flex;\n' + "    flex-wrap: wrap;\n" + "    justify-content: center;\n" + "    align-items: center;\n" + "    max-height: 400px;\n" + '    overflow-y: auto;">';

            images.forEach((item) => {
                swalContent += '<img src="data:image/jpeg;base64,' + item.base64File + '" style="max-width: 100%;\n' + "    max-height: 100%;\n" + "    object-fit: contain;\n" + '    margin: 10px;">';
            });
            swalContent += "</div>";

            Swal.fire({
                title: "Images Gabarits",
                html: swalContent,
                showCloseButton: true,
                showConfirmButton: false,
            });
        });
    }

    autoLinkVariableProject(action, type, indexVariable, indexInput = null) {
        let variableProjects = [];
        
        if(type == 'input'){
            
            this.form.controls.variables['controls'][indexVariable].controls.inputs['controls'][indexInput].controls['variableProject'].setValue([])
            let fieldType = this.form.value.variables[indexVariable].inputs[indexInput].type;
            if (fieldType && this.form.value.variables[indexVariable].inputs[indexInput].name && this.variableProjects["input"] && this.variableProjects["input"][fieldType]) {
                variableProjects = this.variableProjects["input"][fieldType];
            }

            if (variableProjects.length > 0) {
                let varProj = variableProjects.filter((varpro) => varpro.name == this.form.value.variables[indexVariable].inputs[indexInput].name);
                if (varProj.length > 0) {
                    if (!this.form.value.variables[indexVariable].inputs[indexInput]["variableProject"] || this.form.value.variables[indexVariable].inputs[indexInput]["variableProject"].length == 0) {
                        this.form.controls.variables["controls"][indexVariable].controls.inputs["controls"][indexInput].controls["variableProject"].setValue(varProj);
                    }
                } else if (action == "type") {
                    this.form.controls.variables["controls"][indexVariable].controls.inputs["controls"][indexInput].controls["variableProject"].setValue([]);
                }
            } else {
                this.form.controls.variables["controls"][indexVariable].controls["variableProject"].setValue([]);
            }
        } else if (type == "variable") {
            let fieldType = this.form.value.variables[indexVariable].type;
            if (fieldType && this.form.value.variables[indexVariable].name && this.variableProjects["variable"] && this.variableProjects["variable"][fieldType]) {
                variableProjects = this.filterTypeVariableProject(type, fieldType);
            }

            if (variableProjects.length > 0) {
                let varProj = variableProjects.filter((varpro) => varpro.name == this.form.value.variables[indexVariable].name);
                if (varProj.length > 0) {
                    if (!this.form.value.variables[indexVariable]["variableProject"] || this.form.value.variables[indexVariable]["variableProject"].length == 0) {
                        this.form.controls.variables["controls"][indexVariable].controls["variableProject"].setValue(varProj);
                    }
                } else if (action == "type") {
                    this.form.controls.variables["controls"][indexVariable].controls["variableProject"].setValue([]);
                }
            } else {
                this.form.controls.variables["controls"][indexVariable].controls["variableProject"].setValue([]);
            }
        } else if (type == "media") {
            if (!this.form.value.positionMedias[indexVariable]["variableProject"] || this.form.value.positionMedias[indexVariable]["variableProject"].length == 0) {
                this.form.controls.positionMedias["controls"][indexVariable].controls["variableProject"].setValue([]);
                if (this.form.value.positionMedias[indexVariable].name && this.variableProjects["media"].length > 0) {
                    let varProj = this.variableProjects["media"].filter((varpro) => varpro.name == this.form.value.positionMedias[indexVariable].name);
                    this.form.controls.positionMedias["controls"][indexVariable].controls["variableProject"].setValue(varProj);
                }
            }
        } else if (type == "option") {
            if (!this.form.value.options[indexVariable]["variableProject"] || this.form.value.options[indexVariable]["variableProject"].length == 0) {
                this.form.controls.options["controls"][indexVariable].controls["variableProject"].setValue([]);
                let fieldType = this.form.value.options[indexVariable].type;
                if (fieldType && this.form.value.options[indexVariable].name && this.variableProjects["option"] && this.variableProjects["option"][fieldType]) {
                    variableProjects = this.filterTypeVariableProject(type, fieldType);
                }

                if (variableProjects.length > 0) {
                    let varProj = variableProjects.filter((varpro) => varpro.name == this.form.value.options[indexVariable].name);
                    if (varProj.length > 0) {
                        if (!this.form.value.options[indexVariable]["variableProject"] || this.form.value.options[indexVariable]["variableProject"].length == 0) {
                            this.form.controls.options["controls"][indexVariable].controls["variableProject"].setValue(varProj);
                        }
                    } else if (action == "type") {
                        this.form.controls.options["controls"][indexVariable].controls["variableProject"].setValue([]);
                    }
                } else {
                    this.form.controls.options["controls"][indexVariable].controls["variableProject"].setValue([]);
                }
            }
        }
    }

    filterTypeVariableProject(type, fieldType) {
        let result = [];
        if (this.variableProjectsNoFilter[type]) {
            switch (fieldType) {
                case "text":
                case "textarea":
                case "number":
                    if (this.variableProjectsNoFilter[type]["text"]) {
                        result = result.concat(this.variableProjectsNoFilter[type]["text"]);
                    }
                    if (this.variableProjectsNoFilter[type]["textarea"]) {
                        result = result.concat(this.variableProjectsNoFilter[type]["textarea"]);
                    }
                    if (this.variableProjectsNoFilter[type]["number"]) {
                        result = result.concat(this.variableProjectsNoFilter[type]["number"]);
                    }
                    if (this.variableProjectsNoFilter[type]["list"]) {
                        result = result.concat(this.variableProjectsNoFilter[type]["list"]);
                    }
                    break;
                default:
                    if (this.variableProjectsNoFilter[type][fieldType]) {
                        result = result.concat(this.variableProjectsNoFilter[type][fieldType]);
                    }
                    break;
            }
        }

        return result;
    }
}
