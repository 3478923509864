import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { SidebarService } from "app/shared/components/sidebar";
import { AdminModuleGuard } from "./guards/admin-module.guard";
import { AuthGuard } from "./guards/auth.guard";
import { ConfirmTabGuard } from "./guards/confirm-tab.guard";
import { ConfirmGuard } from "./guards/confirm.guard";
import { DamModuleGuard } from "./guards/dam-module.guard";
import { DashboardModuleGuard } from "./guards/dashboard-module.guard";
import { PimModuleGuard } from "./guards/pim-module.guard";
import { ProjectsModuleGuard } from "./guards/projects-module.guard";
import { UploadModuleGuard } from "./guards/upload-module.guard";
import { AuthService } from "./services/admin/auth/auth.service";
import { PasswordService } from "./services/admin/auth/password.service";
import { RoleService } from "./services/admin/role/role.service";
import { MessageInformationService } from "./services/admin/user/MessageInformation.service";
import { NotificationService } from "./services/admin/user/notification.service";
import { UserService } from "./services/admin/user/user.service";
import { AdminWorkflowService } from "./services/admin/workflow/admin-workflow.service";
import { DamWorkflowService } from "./services/admin/workflow/dam-workflow.service";
import { PimWorkflowService } from "./services/admin/workflow/pim-workflow.service";
import { ProjectWorkflowService } from "./services/admin/workflow/project-workflow.service";
import { DataModelService } from "./services/dam/datamodel.service";
import { MediaService } from "./services/dam/media.service";
import { DashboardService } from "./services/dashboard/dashboard.service";
import { DataService } from "./services/global/data/data.service";
import { HistoryService } from "./services/global/history/history.service";
import { ActionButtonService } from "./services/global/layout/action.button.service";
import { BreadcrumbFlatplanToolbarService } from "./services/global/layout/bread-crumb-flatplan-toolbar.service";
import { BreadcrumbService } from "./services/global/layout/breadcrumb.service";
import { GeneralActionButtonsService } from "./services/global/layout/general.action.buttons.service";
import { ImportButtonService } from "./services/global/layout/import.button.service";
import { LayoutService } from "./services/global/layout/layout.service";
import { BreadcrumbMenuService } from "./services/global/layout/menu.service";
import { SearchInputService } from "./services/global/layout/search-input.service";
import { ViewButtonsService } from "./services/global/layout/view.buttons.service";
import { SwalModalService } from "./services/global/modal/modal.service";
import { SpinnerService } from "./services/global/spinner/spinner.service";
import { TableService } from "./services/global/table/table.service";
import { ToastService } from "./services/global/toast/toast.service";
import { TreeService } from "./services/global/tree/tree.service";
import { ElementTypeService } from "./services/pim/element-type.service";
import { ElementService } from "./services/pim/element.service";
import { ElementExportService } from "./services/pim/export.service";
import { ElementExportDocxService } from "./services/pim/export/docx.service";
import { ElementExportPdfService } from "./services/pim/export/pdf.service";
import { ElementExportSheetService } from "./services/pim/export/sheet.service";
import { FieldsetService } from "./services/pim/fieldset.service";
import { HomeService } from "./services/pim/home.service";
import { ListService } from "./services/pim/list.service";
import { ListCascadeService } from "./services/pim/listcascade.service";
import { TabService } from "./services/pim/tab.service";
import { VersionService } from "./services/pim/version.service";
import { ExportPageService } from "./services/project/export/export-page-view.service";
import { ProjectExportService } from "./services/project/export/export.service";
import { FlatplanViewService } from "./services/project/export/flatplan-view.service";
import { PageOptionTabService } from "./services/project/export/page-option-tab.service";
import { PageTextTabService } from "./services/project/export/page-text-tab.service";
import { PageThumbnailService } from "./services/project/export/page-thumbnail.service";
import { PageService } from "./services/project/export/page.service";
import { SocialService } from "./services/project/export/social/social.service";
import { ProjectDashboardService } from "./services/project/project/dashboard.service";
import { ElementsConfigurationService } from "./services/project/project/elements.service";
import { ExportsConfigurationService } from "./services/project/project/exports.service";
import { ProjectService } from "./services/project/project/project.service";
import { TemplateConfigurationService } from "./services/project/project/template.service";
import { VariableProjectService } from "./services/project/project/variable.service";
import { WorkflowConfigurationService } from "./services/project/project/workflow.service";
import { TemplateService } from "./services/project/template/template.service";
import { TagService } from "./services/tag/tag.service";
import { PreviewService } from "./services/thumbnails/preview.service";
import { ThumbnailsService } from "./services/thumbnails/thumbnails.service";
import { UploadService } from "./services/upload/upload.service";
import { WorkflowService } from "./services/workflow/workflow.service";

const guards = [AuthGuard, ConfirmGuard, ConfirmTabGuard, AdminModuleGuard, DamModuleGuard, DashboardModuleGuard, PimModuleGuard, ProjectsModuleGuard, UploadModuleGuard];

const layoutServices = [
    ActionButtonService,
    ImportButtonService,
    BreadcrumbFlatplanToolbarService,
    BreadcrumbMenuService,
    BreadcrumbService,
    GeneralActionButtonsService,
    SearchInputService,
    ViewButtonsService,
];
const globalServices = [
    SpinnerService,
    LayoutService,
    ...layoutServices,
    SidebarService,
    SwalModalService,
    ToastService,
    HistoryService,
    TableService,
    TreeService,
    DataService,
    MessageInformationService,
];

const dashboardServices = [DashboardService];

const adminWorkflowServices = [PimWorkflowService, ProjectWorkflowService, DamWorkflowService, AdminWorkflowService];
const adminServices = [UserService, AuthService, RoleService, PasswordService, NotificationService, ...adminWorkflowServices];

const tagServices = [TagService];
const thumbnailServices = [ThumbnailsService, PreviewService];

const damServices = [MediaService, DataModelService];

const pimServices = [
    ElementExportDocxService,
    ElementExportPdfService,
    ElementExportSheetService,
    ElementExportService,
    ElementTypeService,
    ElementService,
    FieldsetService,
    ListService,
    ListCascadeService,
    TabService,
    VersionService,
    HomeService,
];

const flatplanServices = [FlatplanViewService];

const projectServices = [
    TemplateService,
    ProjectService,
    ProjectDashboardService,
    ElementsConfigurationService,
    ExportsConfigurationService,
    TemplateConfigurationService,
    WorkflowConfigurationService,
    ProjectExportService,
    ExportPageService,
    PageOptionTabService,
    PageTextTabService,
    PageService,
    PageThumbnailService,
    ...flatplanServices,
    SocialService,
    VariableProjectService,
];

const workflowServices = [WorkflowService];

const uploadServices = [UploadService];

@NgModule({
    imports: [HttpClientModule],
    providers: [...globalServices, adminServices, guards, uploadServices, tagServices, thumbnailServices, damServices, dashboardServices, pimServices, workflowServices, projectServices],
})
export class CoreModule {}
